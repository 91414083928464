import React,{useState} from 'react'
import "./contacts.css"
import axios from 'axios'
import { Alert, Button } from '@mui/material';


const Contacts = ({serverURL}) => {
  document.title="Contacts | Journey"
  
  const [alertInfo, setAlertInfo] = useState()
  function Alerts({text}) {
    const [open, setOpen] = useState(true);
    return (
      <Alert
        severity={alertInfo.type}
        open={open}
        className="alert"
        style={alertInfo.type==="error"?{backgroundColor:"var(--red)"}:{backgroundColor:"var(--green)"}}
        action={
          <Button
            onClick={() => {
              setOpen(false)
              setAlertInfo(null)
            }}
            color="black"
          >
            <svg width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
            </svg>
          </Button>
        }
      >{text}</Alert>
    );
  }

  const sendEmail=async()=>{
    try{
      await axios.put(serverURL+"sendEmail",{
        email:document.getElementById("email").value,
        oggetto:document.getElementById("oggetto").value,
        testo:document.getElementById("testo").value,
      },{headers:{'Content-Type':'multipart/form-data'}}).then(e=>{
        if(e.status!==200){
          setAlertInfo({type:'error',text:"Error: "+e.data})
        }else{
          setAlertInfo({type:'success',text:'Request Sent: Request sent successfully'})
        }
      })
    }catch(err){
      setAlertInfo({type:'error',text:"Error: "+err})
    }
  }
  return (
    <div className='contacts'>
      {alertInfo&&<Alerts text={alertInfo.text}/>}
      <div className='contacts-content'>
        <form className='contacts-content-form'>
          <div className='contacts-content-form-title'>
            <svg viewBox="0 0 16 16">
              <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z"/>
              <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
            </svg>
            <h1>Contact Us</h1>
          </div>
          <br/>
          <div className='contacts-content-form-item'>
            <input placeholder='Email...' id='email' type="text" required/>
          </div>
          <div className='contacts-content-form-item'>
            <input placeholder='Object...' id='oggetto' type="text" required/>
          </div>
          <div className='contacts-content-form-item'>
            <textarea placeholder='Text...' id="testo" cols="30" rows="10" required></textarea>
          </div>
          <br />
          <div className='contacts-content-form-item'>
            <button onClick={()=>sendEmail()}>Send</button>
          </div>
        </form>
      </div>
      <br />
      <br />
    </div>
  )
}

export default Contacts