import React,{useState,useEffect} from 'react'
import "./utente.css"
import axios from "axios"
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import worldGeoJSON from './custom.geo.json';
import { Alert, Button } from '@mui/material';

const Utente = ({serverURL,utente}) => {
  document.title="Profile | Journey"

  const [alertInfo, setAlertInfo] = useState()
  function Alerts({text}) {
    const [open, setOpen] = useState(true);
    return (
      <Alert
        severity={alertInfo.type}
        open={open}
        className="alert"
        style={alertInfo.type==="error"?{backgroundColor:"var(--red)"}:{backgroundColor:"var(--green)"}}
        action={
          <Button
            onClick={() => {
              setOpen(false)
              setAlertInfo(null)
            }}
            color="black"
          >
            <svg width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
            </svg>
          </Button>
        }
      >{text}</Alert>
    );
  }

  const [caricamento, setCaricamento] = useState(false)
  const delet =()=>{
    setCaricamento(true)
    axios.put(serverURL+"delete",{
      email:utente.email,
      password:utente.password,
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        if(e.status===200){
          document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          window.location.href="/"
        }else{
          setAlertInfo({type:'error',text:'Error: '+e.data})
        }
        setCaricamento(false)
    })
  }
  const [counterVisite, setCounterVisite] = useState(0)
  const ShowVisitedCountries = () => {
    const [visitedCountries, setVisitedCountries] = useState([]);
    useEffect(() => {
      const fetchVisitedCountries = async () => {
        let datas = [];
        if (utente?.cronology) {
          datas = utente.cronology;
        } else if (localStorage.getItem("cronology")) {
          datas = JSON.parse(localStorage.getItem("cronology"));
        }
        const countries = await Promise.all(
          datas.map(async (item) => {
            if (!item.lat || !item.lon) return null;
            const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${item.lat}&lon=${item.lon}&accept-language=en`;
            try {
              const response = await fetch(url);
              if (!response.ok) throw new Error("Errore nella richiesta API");
              const data = await response.json();
              return data.address?.country || null;
            } catch (error) {
              console.error("Errore nel recupero del paese:", error);
              return null;
            }
          })
        );
        const uniqueArray = [...new Set(countries.filter(Boolean))]; // Rimuove duplicati e null
        setCounterVisite(uniqueArray.length);
        setVisitedCountries(uniqueArray);
      };
      fetchVisitedCountries().catch(console.error);
    }, [utente]);
    const countryStyle = (feature) => ({
      fillColor: visitedCountries.includes(feature.properties.admin) ? "black" : null,
      weight: visitedCountries.includes(feature.properties.admin) ? 1 : 0,
      color: visitedCountries.includes(feature.properties.admin) ? "black" : null,
      fillOpacity: visitedCountries.includes(feature.properties.admin) ? 0.1 : 0,
    });
    return (
      <MapContainer center={[20, 0]} zoom={1} style={{ height: "100%", width: "100%" }} 
        zoomControl={false} scrollWheelZoom={false} dragging={false} touchZoom={false} doubleClickZoom={false}>   
        <TileLayer url="https://www.google.com/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}" attribution="Google Maps" />
        {visitedCountries.length > 0 && <GeoJSON data={worldGeoJSON} style={countryStyle} />}
      </MapContainer>
    );
  };  
  return (
    <div className='utente'>
      {caricamento&&<div className='lds'><div className='lds-c'><div className="lds-spinner"></div><div className='fade-text-container'><p className="fade-text">Loading data from servers...</p><p className="fade-text">Translation of content into the selected language...</p><p className="fade-text">Preparing your personalized user experience...</p><p className="fade-text">Please wait thanks...</p></div></div></div>}
      {alertInfo&&<Alerts text={alertInfo.text}/>}
      <div className='utente-content'>
        <div className='utente-title'>
          <h1>Profile and Settings</h1>
        </div>
        <div className='utente-content-anonymous-grid'>
          <div className='utente-content-anonymous-grid-sx'>
            <svg viewBox="0 0 16 16">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
              <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
            </svg>
          </div>
          <div className='utente-content-anonymous-grid-dx'>
            <div className='utente-content-anonymous-grid-dx-item'>
              <h3>{utente?utente.nome:'Anonymous User'}</h3>
            </div>
            <div className='utente-content-anonymous-grid-dx-item'>
              <svg viewBox="0 0 16 16">
                <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z"/>
                <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z"/>
                <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5"/>
              </svg>
              <big>Recently Opened: {utente?(utente.cronology?utente.cronology.length:0):'?'}</big>
            </div>
            <div className='utente-content-anonymous-grid-dx-item'>
              <svg viewBox="0 0 16 16">
                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15"/>
              </svg>
              <big>Prefered: {utente?(localStorage.getItem("prefered")?JSON.parse(localStorage.getItem("prefered")).length:0):'?'}</big>
            </div>
            <div className='utente-content-anonymous-grid-dx-item'>
              <svg viewBox="0 0 16 16">
                <path d="M4.04 7.43a4 4 0 0 1 7.92 0 .5.5 0 1 1-.99.14 3 3 0 0 0-5.94 0 .5.5 0 1 1-.99-.14"/>
                <path fill-rule="evenodd" d="M4 9.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm1 .5v3h6v-3h-1v.5a.5.5 0 0 1-1 0V10z"/>
                <path d="M6 2.341V2a2 2 0 1 1 4 0v.341c2.33.824 4 3.047 4 5.659v1.191l1.17.585a1.5 1.5 0 0 1 .83 1.342V13.5a1.5 1.5 0 0 1-1.5 1.5h-1c-.456.607-1.182 1-2 1h-7a2.5 2.5 0 0 1-2-1h-1A1.5 1.5 0 0 1 0 13.5v-2.382a1.5 1.5 0 0 1 .83-1.342L2 9.191V8a6 6 0 0 1 4-5.659M7 2v.083a6 6 0 0 1 2 0V2a1 1 0 0 0-2 0M3 13.5A1.5 1.5 0 0 0 4.5 15h7a1.5 1.5 0 0 0 1.5-1.5V8A5 5 0 0 0 3 8zm-1-3.19-.724.362a.5.5 0 0 0-.276.447V13.5a.5.5 0 0 0 .5.5H2zm12 0V14h.5a.5.5 0 0 0 .5-.5v-2.382a.5.5 0 0 0-.276-.447L14 10.309Z"/>
              </svg>
              <big>How Many Visited: {utente?counterVisite:'?'}</big>
            </div>
          </div>   
        </div>
        <br />
        {utente?<div className='utente-content-info'>
          <div className='utente-content-info-item'>
            email:
            <big>{utente.email}</big>
          </div>
          <div className='utente-content-info-item'>
            password:
            <big>{utente.password}</big>
          </div>
          <div className='utente-content-info-item'>
            name:
            <br /><big>{utente.nome}</big>
          </div>
          <div className='utente-content-info-item'>
            profession:
            <big>{utente.professione}</big>
          </div>
          <div className='utente-content-info-item'>
            register date:
            <big>{new Date(utente.data).getFullYear()}-{new Date(utente.data).getMonth()+1}-{new Date(utente.data).getDate()}</big>
          </div>
        </div>:<div className='utente-content-anonymous-bottom'>
          <p>Log in to see the statistics</p>
          <a href="/login">Login</a>
        </div>}
        <br />
        <div className='utente-content-title'>
          <h2>My Worldwidness</h2>
        </div>
        {!utente&&<div className='utente-content-map-black'><p>Login to see it!</p></div>}
        <div className='utente-content-map'>
          <ShowVisitedCountries/>
        </div>
        <br />
        <br />
        <br />
        {utente&&<div className='utente-content-button'>
          <div className='utente-content-button-item'>
            <button onClick={()=>{
              document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              window.location.href="/"
            }}>Logout</button>
          </div>
          <div className='utente-content-button-item'>
            <button onClick={()=>delet()}>Delete Profile</button>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default Utente