import React from 'react'
import "./intro.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, Scrollbar, A11y } from "swiper/modules";
import { Box } from "@mui/material";

const Intro = () => {
  const backgroundImages = [
    "https://images.pexels.com/photos/2070485/pexels-photo-2070485.jpeg?auto=compress&cs=tinysrgb&w=3600",
    "https://images.pexels.com/photos/586687/pexels-photo-586687.jpeg?auto=compress&cs=tinysrgb&w=3600",
    "https://images.pexels.com/photos/6498686/pexels-photo-6498686.jpeg?auto=compress&cs=tinysrgb&w=3600",
    "https://images.pexels.com/photos/2179666/pexels-photo-2179666.jpeg?auto=compress&cs=tinysrgb&w=3600",
    "https://images.pexels.com/photos/2516418/pexels-photo-2516418.jpeg?auto=compress&cs=tinysrgb&w=3600",
    "https://images.pexels.com/photos/2659475/pexels-photo-2659475.jpeg?auto=compress&cs=tinysrgb&w=3600",
  ];

  return (
    <div className='intro'>
      <div className='intro-slider'>
        <Swiper
          modules={[Pagination, Autoplay, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000 }}
          style={{
            height: "100%",
            position:"absolute",
            width:"100%",
            "--swiper-pagination-color": "white",
          }}
        >
          {backgroundImages.map((src, index) => (
            <SwiperSlide key={index}>
              <Box component="img" src={src} alt={`Slide ${index + 1}`} sx={{height: "100%",width:"100%"}} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className='intro-container'>
        <div className='intro-container-text'>
            <h1>Let's Make <div style={{fontWeight:"900"}}>Your Digital Trip</div></h1>
            <a href='/home'>Get Started!</a>
        </div>
      </div>
    </div>
  )
}

export default Intro