import React, { useState,useEffect } from 'react'
import "./login.css"
import axios from 'axios'
import img from "../../logo.jpg"
import { Alert, Button } from '@mui/material';


const Login = ({serverURL}) => {
    document.title="Login | Journey"

    const [alertInfo, setAlertInfo] = useState()
    function Alerts({text}) {
      const [open, setOpen] = useState(true);
      return (
        <Alert
          severity={alertInfo.type}
          open={open}
          className="alert"
          style={alertInfo.type==="error"?{backgroundColor:"var(--red)"}:{backgroundColor:"var(--green)"}}
          action={
            <Button
              onClick={() => {
                setOpen(false)
                setAlertInfo(null)
              }}
              color="black"
            >
              <svg width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>
            </Button>
          }
        >{text}</Alert>
      );
    }

    const [caricamento,setCaricamento]=useState(false)
    const replaceSpaces=()=>{
        document.getElementById("nome").value=document.getElementById("nome").value.replace(/ /g, "/")
    }
    const signup=()=>{
        setCaricamento(true)
        axios.put(serverURL+"signup",{
            email:document.getElementById("email").value,
            password:document.getElementById("password").value,
            nome:document.getElementById("nome").value.replace("/"," "),
            professione:document.getElementById("professione").value,
            nascita:document.getElementById("nascita").value,
            data:new Date()
        },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
            setCaricamento(false)
            if(e.status===200){
                let date=new Date()
                date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000))
                const expires=date.toUTCString()
                document.cookie="email="+e.data.email+"; expires="+expires
                document.cookie="password="+e.data.password+"; expires="+expires
                setAlertInfo({type:'success',text:"Registration Successful"})
                setTimeout(()=>window.location.href="/explore",2000)
            }else{
                setAlertInfo({type:'error',text:"Register Error: "+e.data})
            }
          }).catch((e)=>{
            setCaricamento(false)
            setAlertInfo({type:'error',text:"Register Error: "+e.data})
        })
    }
    const login=()=>{
        setCaricamento(true)
        axios.put(serverURL+"login",{
            email:document.getElementById("email").value,
            password:document.getElementById("password").value,
        },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
            setCaricamento(false)
            if(e.status===200){
                let date=new Date()
                date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000))
                const expires=date.toUTCString()
                document.cookie="email="+e.data.email+"; expires="+expires
                document.cookie="password="+e.data.password+"; expires="+expires
                setAlertInfo({type:'success',text:"Login Successful"})
                setTimeout(()=>window.location.href="/explore",2000)
            }else{
              setAlertInfo({type:'error',text:"Login Error: "+e.data})
            }
        }).catch((e)=>{
            setCaricamento(false)
            setAlertInfo({type:'error',text:"Login Error: "+e})
        })
    }
    useEffect(()=>{
        const timer=setTimeout(()=>{
            if(document.cookie.split(";").find(i=>i.includes("email"))&&document.cookie.split(";").find(i=>i.includes("password"))){
                window.location.href="/profile"
            }
        },1000);
        return ()=>clearTimeout(timer);
    },[]);
  return (
    <div className='login'>
        {alertInfo&&<Alerts text={alertInfo.text}/>}
        {caricamento&&<div className='lds'><div className='lds-c'><div className="lds-spinner"></div><div className='fade-text-container'><p class="fade-text">Loading data from servers...</p><p class="fade-text">Translation of content into the selected language...</p><p class="fade-text">Preparing your personalized user experience...</p><p class="fade-text">Please wait thanks...</p></div></div></div>}
        <div className='login-content'>
            {window.location.search.substring(1).split("=")[1]==="true"&&window.location.search.substring(1).split("=")[0]==="signup"?<form className='login-content-form' onSubmit={(e)=>{
                e.preventDefault()
                signup()
            }}>
                <div className='login-content-title'>
                    <img style={{borderRadius:"50%"}} src={img} alt="logo" />
                    <h1>Signup</h1>
                </div>
                <br />
                <div className='login-content-form-item'>
                    <input type="email" id='email' placeholder='Email...' required/>
                </div>
                <div className='login-content-form-item'>
                    <input type="password" id='password' placeholder='Password...' required/>
                </div>
                <div className='login-content-form-item'>
                    <input type="text" id='nome' onInput={()=>replaceSpaces()} placeholder='Name/Surname...' required/>
                </div>
                <div className='login-content-form-item'>
                    <select id="professione" required>
                        <option value="Healthcare and Assistance">Healthcare and Assistance</option>
                        <option value="Legal">Legal</option>
                        <option value="Education and Instruction">Education and Instruction</option>
                        <option value="Information Technology">Information Technology</option>
                        <option value="Engineering and Architecture">Engineering and Architecture</option>
                        <option value="Commerce and Finance">Commerce and Finance</option>
                        <option value="Art and Design">Art and Design</option>
                        <option value="Science and Research">Science and Research</option>
                        <option value="Production and Manufacturing">Production and Manufacturing</option>
                        <option value="Marketing and Comunication">Marketing and Comunication</option>
                        <option value="Student">Student</option>
                    </select>
                </div>
                <div className='login-content-form-item'>
                    <input type="date" id='nascita' required/>
                </div>
                <br />
                <div className='login-content-form-item'>
                    <button>Send</button>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className='login-content-form-small'>
                    <div onClick={()=>window.location.href=window.location.pathname}>Login</div>
                </div>
            </form>:<form className='login-content-form' onSubmit={(e)=>{
                e.preventDefault()
                login()
            }}>
                <div className='login-content-title'>
                    <img style={{borderRadius:"50%"}} src={img} alt="logo" />
                    <h1>Login</h1>
                </div>
                <br />
                <div className='login-content-form-item'>
                    <input type="email" id='email' placeholder='Email...' required/>
                </div>
                <div className='login-content-form-item'>
                    <input type="password" id='password' placeholder='Password...' required/>
                </div>
                <br />
                <div className='login-content-form-item'>
                    <button>Send</button>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className='login-content-form-small'>
                    <div onClick={()=>window.location.href=window.location.pathname+"?signup=true"}>Don't You Have an Account? Signup</div>
                </div>
            </form>}
        </div>
        <br />
        <br />
    </div>
  )
}

export default Login