import React, { useEffect, useState } from "react";
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import axios from "axios"
import Intro from "./components/intro/Intro"
import Home from "./components/home/Home"
import Explore from "./components/explore/Explore";
import Navbar from "./components/navbar/Navbar";
import About from "./components/about/About";
import Contacts from "./components/contacts/Contacts";
import Login from "./components/login/Login";
import Utente from "./components/utente/Utente";
import Footer from "./components/footer/Footer";
import Install from "./components/installation/Install";
import PageError from "./components/pageError/PageError";
import { Alert, Button } from '@mui/material';

function App(){

  const [alertInfo, setAlertInfo] = useState()
  function Alerts({text}) {
    const [open, setOpen] = useState(true);
    return (
      <Alert
        severity={alertInfo.type}
        open={open}
        className="alert"
        style={alertInfo.type==="error"?{backgroundColor:"var(--red)"}:{backgroundColor:"var(--green)"}}
        action={
          <Button
            onClick={() => {
              setOpen(false)
              setAlertInfo(null)
            }}
            color="black"
          >
            <svg width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
            </svg>
          </Button>
        }
      >{text}</Alert>
    );
  }

  const [utente,setUtente]=useState()
  const serverURL="https://cultura-server.onrender.com/"
  //const serverURL="http://localhost:3001/"
  const stayLogin=()=>{
    if(document.cookie.split(";").find(i=>i.includes("email"))&&document.cookie.split(";").find(i=>i.includes("password"))){
      axios.put(serverURL+"login",{
        email:document.cookie.split(";").find(i=>i.includes("email")).trim().split("=")[1],
        password:document.cookie.split(";").find(i=>i.includes("password")).trim().split("=")[1],
      },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        if(e.status===200){
          setUtente(e.data)
          let date=new Date()
          date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000))
          const expires=date.toUTCString()
          document.cookie="email="+document.cookie.split(";").find(i=>i.includes("email")).trim().split("=")[1]+"; expires="+expires
          document.cookie="password="+document.cookie.split(";").find(i=>i.includes("password")).trim().split("=")[1]+"; expires="+expires
        }else{
          setAlertInfo({type:'error',text:'Error: You are not logged in'})
          document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          setUtente(203)
        }
      })
    }
  }
  const handleError = (error) => {
    setAlertInfo({type:'error',text:'Error: '+JSON.stringify(error)})
  };
  useEffect(()=>{
    stayLogin()
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);
  },[])
  
  return (
    <BrowserRouter>
      {!window.location.pathname.endsWith("/")&&<Navbar utente={utente}/>}
      {alertInfo&&<Alerts text={alertInfo.text}/>}
      <Routes>
        <Route path='/' element={<Intro/>}/>
        <Route path='/home' element={<Home serverURL={serverURL} utente={utente}/>}/>
        <Route path='/who' element={<About/>}/>
        <Route path='/contacts' element={<Contacts serverURL={serverURL}/>}/>
        <Route path='/explore' element={<Explore serverURL={serverURL} utente={utente}/>}/>
        <Route path='/login' element={<Login serverURL={serverURL}/>}/>
        <Route path='/profile' element={<Utente serverURL={serverURL} utente={utente}/>}/>
        <Route path='/add_home_page' element={<Install serverURL={serverURL} utente={utente}/>}/>
        <Route path='/*' element={<PageError />}/>
      </Routes>
      {!window.location.pathname.endsWith("/")&&!window.location.pathname.includes("explore")&&<Footer/>}
    </BrowserRouter>
  );
}

export default App;
